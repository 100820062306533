<template>
  <!-- COLORS -->

  <h2>Colors</h2>
  <p class="styleguide-p-sourcecode">
    <em>Source code: <code>scss/base/_colors.scss</code></em>
  </p>

  <!-- PRIMARY -->

  <h3 class="styleguide-h3">Primary colors</h3>
  <div class="styleguide-color-blocks">
    <div class="styleguide-color-block bg-primary-lightest">
      <div class="styleguide-color-block__label">
        $color-primary-lightest
      </div>
    </div>
    <div class="styleguide-color-block bg-primary-lighter">
      <div class="styleguide-color-block__label">
        $color-primary-lighter
      </div>
    </div>
    <div class="styleguide-color-block bg-primary-light">
      <div class="styleguide-color-block__label">$color-primary-light</div>
    </div>
    <div class="styleguide-color-block bg-primary-lighty">
      <div class="styleguide-color-block__label">$color-primary-lighty</div>
    </div>
    <div class="styleguide-color-block bg-primary">
      <div class="styleguide-color-block__label">$color-primary</div>
    </div>
    <div class="styleguide-color-block bg-primary-darky">
      <div class="styleguide-color-block__label">$color-primary-darky</div>
    </div>
    <div class="styleguide-color-block bg-primary-dark">
      <div class="styleguide-color-block__label">$color-primary-dark</div>
    </div>
    <div class="styleguide-color-block bg-primary-darker">
      <div class="styleguide-color-block__label">$color-primary-darker</div>
    </div>
    <div class="styleguide-color-block bg-primary-darkest">
      <div class="styleguide-color-block__label">
        $color-primary-darkest
      </div>
    </div>
  </div>

  <!-- SECONDARY -->

  <h3 class="styleguide-h3">Secondary colors</h3>
  <div class="styleguide-color-blocks">
    <div class="styleguide-color-block bg-secondary-lightest">
      <div class="styleguide-color-block__label">
        $color-secondary-lightest
      </div>
    </div>
    <div class="styleguide-color-block bg-secondary-lighter">
      <div class="styleguide-color-block__label">
        $color-secondary-lighter
      </div>
    </div>
    <div class="styleguide-color-block bg-secondary-light">
      <div class="styleguide-color-block__label">
        $color-secondary-light
      </div>
    </div>
    <div class="styleguide-color-block bg-secondary-lighty">
      <div class="styleguide-color-block__label">
        $color-secondary-lighty
      </div>
    </div>
    <div class="styleguide-color-block bg-secondary">
      <div class="styleguide-color-block__label">$color-secondary</div>
    </div>
    <div class="styleguide-color-block bg-secondary-darky">
      <div class="styleguide-color-block__label">
        $color-secondary-darky
      </div>
    </div>
    <div class="styleguide-color-block bg-secondary-dark">
      <div class="styleguide-color-block__label">$color-secondary-dark</div>
    </div>
    <div class="styleguide-color-block bg-secondary-darker">
      <div class="styleguide-color-block__label">$color-secondary-darker</div>
    </div>
    <div class="styleguide-color-block bg-secondary-darkest">
      <div class="styleguide-color-block__label">$color-secondary-darkest</div>
    </div>
  </div>

  <!-- ACCENT -->

  <h3 class="styleguide-h3">Accent colors</h3>
  <div class="styleguide-color-blocks">
    <div class="styleguide-color-block bg-accent-lightest">
      <div class="styleguide-color-block__label">
        $color-accent-lightest
      </div>
    </div>
    <div class="styleguide-color-block bg-accent-lighter">
      <div class="styleguide-color-block__label">$color-accent-lighter</div>
    </div>
    <div class="styleguide-color-block bg-accent-light">
      <div class="styleguide-color-block__label">$color-accent-light</div>
    </div>
    <div class="styleguide-color-block bg-accent-lighty">
      <div class="styleguide-color-block__label">$color-accent-lighty</div>
    </div>
    <div class="styleguide-color-block bg-accent">
      <div class="styleguide-color-block__label">$color-accent</div>
    </div>
    <div class="styleguide-color-block bg-accent-darky">
      <div class="styleguide-color-block__label">$color-accent-darky</div>
    </div>
    <div class="styleguide-color-block bg-accent-dark">
      <div class="styleguide-color-block__label">$color-accent-dark</div>
    </div>
    <div class="styleguide-color-block bg-accent-darker">
      <div class="styleguide-color-block__label">$color-accent-darker</div>
    </div>
    <div class="styleguide-color-block bg-accent-darkest">
      <div class="styleguide-color-block__label">$color-accent-darkest</div>
    </div>
  </div>

  <!-- GRAY -->

  <h3 class="styleguide-h3">Gray colors</h3>
  <div class="styleguide-color-blocks">
    <div class="styleguide-color-block bg-gray-lightest">
      <div class="styleguide-color-block__label">$color-gray-lightest</div>
    </div>
    <div class="styleguide-color-block bg-gray-lighter">
      <div class="styleguide-color-block__label">$color-gray-lighter</div>
    </div>
    <div class="styleguide-color-block bg-gray-light">
      <div class="styleguide-color-block__label">$color-gray-light</div>
    </div>
    <div class="styleguide-color-block bg-gray-lighty">
      <div class="styleguide-color-block__label">$color-gray-lighty</div>
    </div>
    <div class="styleguide-color-block bg-gray">
      <div class="styleguide-color-block__label">$color-gray</div>
    </div>
    <div class="styleguide-color-block bg-gray-darky">
      <div class="styleguide-color-block__label">$color-gray-darky</div>
    </div>
    <div class="styleguide-color-block bg-gray-dark">
      <div class="styleguide-color-block__label">$color-gray-dark</div>
    </div>
    <div class="styleguide-color-block bg-gray-darker">
      <div class="styleguide-color-block__label">$color-gray-darker</div>
    </div>
    <div class="styleguide-color-block bg-gray-darkest">
      <div class="styleguide-color-block__label">$color-gray-darkest</div>
    </div>
    <div class="styleguide-color-block bg-gray-blueish">
      <div class="styleguide-color-block__label">$color-gray-blueish</div>
    </div>
  </div>

  <!-- UNIVERSAL -->

  <h3 class="styleguide-h3">Universal colors</h3>
  <div class="styleguide-color-blocks">
    <div class="styleguide-color-block bg-error">
      <div class="styleguide-color-block__label">$color-error</div>
    </div>
    <div class="styleguide-color-block bg-warning">
      <div class="styleguide-color-block__label">$color-warning</div>
    </div>
    <div class="styleguide-color-block bg-info">
      <div class="styleguide-color-block__label">$color-info</div>
    </div>
    <div class="styleguide-color-block bg-success">
      <div class="styleguide-color-block__label">$color-success</div>
    </div>
  </div>

  <!-- MATERIAL -->

  <h3 class="styleguide-h3">"Material" colors</h3>
  <div class="styleguide-color-blocks">
    <div class="styleguide-color-block bg-material-red">
      <div class="styleguide-color-block__label">$color-material-red</div>
    </div>
    <div class="styleguide-color-block bg-material-pink">
      <div class="styleguide-color-block__label">$color-material-pink</div>
    </div>
    <div class="styleguide-color-block bg-material-purple">
      <div class="styleguide-color-block__label">$color-material-purple</div>
    </div>
    <div class="styleguide-color-block bg-material-deeppurple">
      <div class="styleguide-color-block__label">$color-material-deeppurple</div>
    </div>
    <div class="styleguide-color-block bg-material-indigo">
      <div class="styleguide-color-block__label">$color-material-indigo</div>
    </div>
    <div class="styleguide-color-block bg-material-blue">
      <div class="styleguide-color-block__label">$color-material-blue</div>
    </div>
    <div class="styleguide-color-block bg-material-lightblue">
      <div class="styleguide-color-block__label">$color-material-lightblue</div>
    </div>
    <div class="styleguide-color-block bg-material-cyan">
      <div class="styleguide-color-block__label">$color-material-cyan</div>
    </div>
    <div class="styleguide-color-block bg-material-teal">
      <div class="styleguide-color-block__label">$color-material-teal</div>
    </div>
    <div class="styleguide-color-block bg-material-green">
      <div class="styleguide-color-block__label">$color-material-green</div>
    </div>
    <div class="styleguide-color-block bg-material-lightgreen">
      <div class="styleguide-color-block__label">$color-material-lightgreen</div>
    </div>
    <div class="styleguide-color-block bg-material-lime">
      <div class="styleguide-color-block__label">$color-material-lime</div>
    </div>
    <div class="styleguide-color-block bg-material-yellow">
      <div class="styleguide-color-block__label">$color-material-yellow</div>
    </div>
    <div class="styleguide-color-block bg-material-amber">
      <div class="styleguide-color-block__label">$color-material-amber</div>
    </div>
    <div class="styleguide-color-block bg-material-orange">
      <div class="styleguide-color-block__label">$color-material-orange</div>
    </div>
    <div class="styleguide-color-block bg-material-deeporange">
      <div class="styleguide-color-block__label">$color-material-deeporange</div>
    </div>
    <div class="styleguide-color-block bg-material-brown">
      <div class="styleguide-color-block__label">$color-material-brown</div>
    </div>
    <div class="styleguide-color-block bg-material-grey">
      <div class="styleguide-color-block__label">$color-material-grey</div>
    </div>
    <div class="styleguide-color-block bg-material-bluegrey">
      <div class="styleguide-color-block__label">$color-material-bluegrey</div>
    </div>
  </div>

  <!-- MICRO-PARCELS -->

  <h3 class="styleguide-h3">Micro-parcels colors</h3>
  <div class="styleguide-color-blocks">
    <div class="styleguide-color-block bg-micro-parcel-background-0">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-0</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-1">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-1</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-2">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-2</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-3">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-3</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-4">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-4</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-5">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-5</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-6">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-6</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-7">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-7</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-8">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-8</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-9">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-9</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-10">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-10</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-11">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-11</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-12">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-12</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-13">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-13</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-14">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-14</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-15">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-15</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-16">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-16</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-17">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-17</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-18">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-18</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-19">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-19</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-20">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-20</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-21">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-21</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-22">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-22</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-23">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-23</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-24">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-24</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-25">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-25</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-26">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-26</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-27">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-27</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-28">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-28</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-29">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-29</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-30">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-30</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-31">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-31</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-32">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-32</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-33">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-33</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-34">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-34</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-background-35">
      <div class="styleguide-color-block__label">$color-micro-parcel-background-35</div>
    </div>
  </div>

  <h3 class="styleguide-h3">Micro-parcels repetitions colors</h3>
  <div class="styleguide-color-blocks">
    <div class="styleguide-color-block bg-micro-parcel-repetition-0">
      <div class="styleguide-color-block__label">$color-micro-parcel-repetition-0</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-repetition-1">
      <div class="styleguide-color-block__label">$color-micro-parcel-repetition-1</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-repetition-2">
      <div class="styleguide-color-block__label">$color-micro-parcel-repetition-2</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-repetition-3">
      <div class="styleguide-color-block__label">$color-micro-parcel-repetition-3</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-repetition-4">
      <div class="styleguide-color-block__label">$color-micro-parcel-repetition-4</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-repetition-5">
      <div class="styleguide-color-block__label">$color-micro-parcel-repetition-5</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-repetition-6">
      <div class="styleguide-color-block__label">$color-micro-parcel-repetition-6</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-repetition-7">
      <div class="styleguide-color-block__label">$color-micro-parcel-repetition-7</div>
    </div>
    <div class="styleguide-color-block bg-micro-parcel-repetition-8">
      <div class="styleguide-color-block__label">$color-micro-parcel-repetition-8</div>
    </div>
  </div>

  <!-- MISSING COLORS -->

  <h3 class="styleguide-h3">Missing colors</h3>
  <p><em>Those SASS colors have no classes associated</em></p>
  <ul>
    <li><strong>Protocol</strong> colors (x5)</li>
    <li><strong>Navigation dropdown</strong> colors (x6)</li>
    <li><strong>Notation status</strong> colors (x6)</li>
    <li><strong>Grouping</strong> colors (x4)</li>
  </ul>

</template>

<script>
export default {
  name: 'StyleguideColorsView',
}
</script>

<style lang="scss" scoped>
/* STYLEGUIDE - COLORS*/

// Color blocks

.styleguide-color-blocks {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
  margin-bottom: calc(#{$gutter} - 4px);
}

.styleguide-color-block {
  flex-basis: 128px;
  min-height: 128px;
  margin: 4px;
  border: 2px solid $color-gray-darker;
  font-size: 12px;
}

.styleguide-color-block__label {
  padding: 2px 4px;
  background-color: $color-gray-darker;
  color: white;
}
</style>
